import React from "react"
import Layout from "../../../Components/layout"

import InquireSection from "../../../Components/inquirelink"
import Banner from "../../../Components/pageComponents/banner"
import SolutionInfoSection from "../../../Components/pageComponents/solutionsInfoSection"
import SEO from "../../../Components/SEO"

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/computing/hyper-converged/Hyper Converged Infrastructure Stock.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        sideImage: file(relativePath: { eq: "products/computing/hyper-converged/Coverged VS Hyper Convreged.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout>
            <SEO title="Products" description="Fastlink offers a wide variety of products that will cater all the consumer and enterprise's needs, wants, and requirements"></SEO>
            <Banner title="Hyper Converged" image={data.banner.childImageSharp.fluid} />
            <SolutionInfoSection heading="Products We Offer" title="Hyper Converged" description="Hyper Converged Infrastructure or HCI decreases the number of hardware used all while increasing the scalability and manageability of the User’s Data Center.
            Hyper convergence is a method to simplify your Data Center without sacrificing its reliability and performance."
                img={data.sideImage.childImageSharp.fluid} />
            <InquireSection />
        </Layout>
    )
}